<template>
  <div class="orders-bg">
    <div class="orders-container container px-0">
      <div class="orders-top-content">
        <Menu></Menu>
      </div>

      <div class="orders-content px-5 py-4">
        <div class="orders-content-topbar pb-4" style="border-bottom: none">
          <div class="row justify-content-center">
            <div class="col-6 col-md-4 text-start text-end text-sm-start order-2 order-sm-1">
              <span class="last-month p-3 ps-5 text-dark d-flex align-middle d-sm-inline-block capitalize cursor-pointer" @click="prevMonth()"
                ><p>{{ prevMonthDate | moment("MMMM YYYY") }}</p></span
              >
            </div>

            <div
              class="
                this-month
                col-12 col-md-4
                text-center
                fw-bold
                fs-4
                pt-3
                order-1
                order-sm-2 
                capitalize
              "
            >
              <p class="mb-3 mb-sm-0">{{ dateStart | moment("MMMM YYYY") }}</p>
            </div>
            <div class="col-6 col-md-4 text-start text-sm-end order-3 order-sm-3">
              <span class="next-month p-3 pe-5 text-dark d-flex align-middle d-sm-inline-block capitalize cursor-pointer" @click="nextMonth()"
                ><p>{{ nextMonthDate | moment("MMMM YYYY") }}</p></span
              >
            </div>
          </div>
        </div>

        <perfect-scrollbar class="navi navi-hover scroll mt-4 month-orders-table" style="position: relative">
          <table class="w-100 table" v-if="orders.data && orders.data.length > 0">
            <thead>
              <tr>
                <th>{{ $t('orders.label.on_day') }}</th>
                <th>{{ $t('orders.label.food_type') }}</th>
                <th>{{ $t('orders.label.order_type') }}</th>
                <th>{{ $t('orders.label.food_name') }}</th>
                <th>{{ $t('orders.label.food_price') }}</th>
                <th>{{ $t('orders.label.order_source') }}</th>
              </tr>
            </thead>
            <tbody>
             
              <tr class="align-top" v-for="(order, index) in orders.data" :key="index">
                <td class="py-1"><span v-if="order.meal_date">{{ order.meal_date | moment("DD.MM.YYYY")  }}</span></td>
                <td>
                  <span v-if="order.meal">{{ order.meal.food_type.name }}</span>
                  <span v-else class="text-muted">-</span>
                </td>

                <td>
                  {{ $t('orders.title.type_' + order.state) }}
                </td>

                <td>
                  <span v-if="order.state != 'PREORDER'">
                    <span style="width: 20px !important; height: 20px; margin-top: 2px;" class="fs-6 fw-light text-white number number-2">{{order.meal_menu_number}}</span> 
                    {{ order.name }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
                <td>{{ order.price }} Kč</td>
                <td>
                   <img class="d-inline-block me-2" style="width: 15px; height: 15px; transform: translateY(-2px);" src="../../../assets/img/internet.png" alt=""><span>{{ $t('orders.title.source_internet')}}</span>
                </td>
              </tr>

            </tbody>
          </table>
          <div v-else>
            <center class="mt-5 text-center text-muted">{{ $t('orders.title.none_found') }}</center>
          </div>
        </perfect-scrollbar>


        <div style="border-top: 1px solid #dadada;" class="table-sum-section pt-4 mt-5 w-100 d-flex justify-content-end align-items-center gap-3">
          <span>{{ $t('orders.title.total') }}:</span>
          <p style="color: #159524; font-weight: 500" class="fs-3" v-if="orders.custom_meta">{{ orders.custom_meta.amount }},-</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import i18n from "@/plugins/vue-i18n";
import Menu from "@/views/core/Menu";
import moment from "@/plugins/moment";

export default {
  metaInfo() {
    return { title: i18n.t("meta.orders_overview") };
  },
  data() {
    return {
      orders: null,
      page: 1,
      totalPrice: 0,
      dateStart: moment(moment(this.dateStart).startOf('month')),
      actualWeek: moment(moment()).format("W"),
      actualYear: moment(moment(this.dateStart)).format("YYYY"),
      monthStart: moment(moment(this.dateStart).startOf('month')),
      monthEnd: moment(moment(this.dateStart).endOf('month')),
      nextMonthDate: moment(moment(this.dateStart).startOf('month')).add(1, "months"),
      prevMonthDate : moment(moment(this.dateStart).startOf('month')).subtract(1, "months")
    };
  },
  components: {
    Menu
  },
  watch: {
    dateStart: function () {
      this.actualYear = moment(moment(this.dateStart)).format("YYYY");
      this.nextMonthDate = moment(moment(this.dateStart).startOf('month')).add(1, "months");
      this.prevMonthDate = moment(moment(this.dateStart).startOf('month')).subtract(1, "months");
      this.monthStart= moment(moment(this.dateStart).startOf('month'));
      this.monthEnd= moment(moment(this.dateStart).endOf('month'));
      this.getOrders();
    },
  },
  mounted() {
    this.getLastSearch();
    this.getOrders();
  },
  computed: {
    orderListSearch: {
      get () {
        return this.$store.state.orders.orderListSearch;
      },
      set (value) {
        this.$store.commit('setOrderListSearch', value);
      }
    }
  },
  methods: {
    getOrders() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("diner/orders?from=" + moment(this.monthStart).format("YYYY-MM-DD") + "&to=" + moment(this.monthEnd).format("YYYY-MM-DD") + "&page="+ this.page +"&perpage=1000").then(
        (response) => {
          this.orders = response.data;
          this.loading = false;
        }
      );
    },
    prevMonth()
    {
      this.dateStart = moment(
        moment(
          moment(this.dateStart).startOf('month')
            .subtract(1, "months")
        )
      );

      this.orderListSearch = {
        dateStart: this.dateStart
      };
    },
    nextMonth()
    {
      this.dateStart = moment(
        moment(
          moment(this.dateStart).startOf('month')
            .add(1, "months")
        )
      );

      this.orderListSearch = {
        dateStart: this.dateStart
      };
    },
    increasePrice(price)
    {
        this.totalPrice += price;
    },
    onUpdatePage(newPage) {
      this.page = newPage;
      this.getOrders();
    },
    getLastSearch()
    {
      let lastSearch = this.$store.state.orders.orderListSearch;
      if(lastSearch.dateStart != null)
      {
        this.dateStart = lastSearch.dateStart;
      }
    }
  },
};
</script>